<template>
  <div v-if="this.rows" class="custom-table no-box-shadow">
    <table-actions
      :actions="['addBtn', 'perPage']"
      :addBtnText="$t('banner.new')"
      :addBtnLink="$helper.getAddUrl('banner')"
      @onSearchInput="inputChange"
      @perPageChange="perPageChange"
      :defaultPerPage="perPage"
    />

    <table-lite
      :is-slot-mode="true"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :sortable="{}"
      @is-finished="isLoading = false"
    >
      <template v-slot:name="data">
        <strong>{{ data.value.name }}</strong>
      </template>

      <template v-slot:actions="data">
        <div class="just-buttons">
          <edit-btn :path="$helper.getEditUrl('banner', data.value.id)" />
          <delete-btn @pressDelete="deleteBanner(data.value['@id'])" />
        </div>
      </template>
    </table-lite>

    <table-pagination
      v-if="pages"
      v-model="currentPage"
      :total="pages"
      :per-page="perPage"
      @input="onChangePage"
    />
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";

import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableActions,
    TableLite,
    TablePagination,
    EditBtn,
    DeleteBtn,
  },
  data() {
    return {
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("banner.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("banner.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.loadBanners();
  },
  methods: {
    loadBanners() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        'order[createdAt]': 'desc'
      };
      this.$Banners.getCollection({ params }, 'id_name').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadBanners();
    },
    deleteBanner(url) {
      this.deleteByUrl(
        this.$Banners,
        url,
        this.$t("banner.deleted"),
        null,
        this.loadBanners
      );
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadBanners();
    },
    onPageChange() {
      this.loadBanners();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/Nestable.scss";
@import "vue-select/src/scss/vue-select.scss";
</style>
