<template>
  <div>
    <title-bar :title="$t('navigations.banners')" />
    <b-card>
      <b-tabs pills v-model="tabIndex">
        <b-tab v-for="tab of tabs" :key="tab.id" :title="tab.title"></b-tab>
      </b-tabs>
      <banner-listing v-show="tabIndex === 0" />
      <slides-listing v-show="tabIndex === 1" />
    </b-card>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import BannerListing from './components/BannersListing.vue'
import SlidesListing from './components/BannerSlidesListing.vue'

export default {
  components: {
    TitleBar,
    BannerListing,
    SlidesListing
  },
  mixins: [ResourceUtils],
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: this.$t("navigations.banners"),
        },
        {
          id: 1,
          title: this.$t("navigations.bannerSlides"),
        },
      ],
      tabIndex: 0,
    };
  },
  created() {

  },
  methods: {

  },
};
</script>
